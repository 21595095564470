var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vessel-data-table"},[_c('base-vessel-data-table',{attrs:{"title":_vm.isCompaniesTable ? 'Companies' : 'Vessels',"headers":_vm.headers,"items":_vm.vessels,"loading":_vm.loading,"item-class":_vm.updateNote,"noCreate":_vm.agencyAdminRole,"is-companies-table":true},on:{"create-item":function($event){return _vm.$emit('create-vessel')}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","min-width":"120"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$emit('update-vessel', item)}}},[_c('v-list-item-title',[_vm._v("Edit")])],1),(!_vm.agencyAdminRole && !_vm.isCompaniesTable)?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('download-terms-conditions', item)}}},[_c('v-list-item-title',[_vm._v("Download T&C")])],1):_vm._e(),(!_vm.agencyAdminRole && !_vm.isCompaniesTable)?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('download-vms-form', item)}}},[_c('v-list-item-title',[_vm._v("Download VMS Request Form")])],1):_vm._e(),(!_vm.agencyAdminRole && !_vm.isCompaniesTable)?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('download-owner-instruction', item)}}},[_c('v-list-item-title',[_vm._v("Download Owner Instruction")])],1):_vm._e(),(!_vm.agencyAdminRole)?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('delete-vessel', item)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1):_vm._e()],1)],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.registrationNumber)+" ")]}},{key:"item.currentContractStartDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.currentContractStartDate))+" ")]}},{key:"item.currentContractEndDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.currentContractEndDate))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }