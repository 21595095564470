































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { DataTableHeader } from "vuetify";
import { getModule } from "vuex-module-decorators";
import Api from "@/api";
import SnackbarModule from "@/store/snackbarModule";
import { ContractType, VesselModel } from "@/api/generated";
import nameof from "@/utility/nameof";
import UserModule from "@/store/userModule";

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

const vesselHeaders: Array<DataTableHeader<VesselModel>> = [
  { text: "", value: "actions", sortable: false },
  { text: "Vessel", value: nameof<VesselModel>("name") },
  { text: "Vessel Type", value: nameof<VesselModel>("vesselTypeName") },
  { text: "IMO", value: nameof<VesselModel>("imoNumber") },
  { text: "Contact Name", value: nameof<VesselModel>("contactName") },
  {
    text: "Contact Telephone",
    value: nameof<VesselModel>("telephoneNumber")
  },
  { text: "Contact Email", value: nameof<VesselModel>("emailAddress") },
  { text: "Agency", value: nameof<VesselModel>("agencyName") },
  {
    text: "Current Contract Start",
    value: nameof<VesselModel>("currentContractStartDate")
  },
  {
    text: "Current Contract End",
    value: nameof<VesselModel>("currentContractEndDate")
  }
];

const companiesHeaders: Array<DataTableHeader<VesselModel>> = [
  { text: "", value: "actions", sortable: false },
  { text: "Company", value: nameof<VesselModel>("name") },
  { text: "Company Type", value: nameof<VesselModel>("vesselTypeName") },
  { text: "Contact Name", value: nameof<VesselModel>("contactName") },
  {
    text: "Contact Telephone",
    value: nameof<VesselModel>("telephoneNumber")
  },
  { text: "Contact Email", value: nameof<VesselModel>("emailAddress") },
  { text: "Agency", value: nameof<VesselModel>("agencyName") },
  {
    text: "Current Contract Start",
    value: nameof<VesselModel>("currentContractStartDate")
  },
  {
    text: "Current Contract End",
    value: nameof<VesselModel>("currentContractEndDate")
  }
]

@Component
export default class VesselDataTable extends Vue {
  @Prop(Boolean) isCompaniesTable?: boolean;

  private loading = false;
  private vessels: Array<VesselModel> = [];
  private headers: Array<DataTableHeader<VesselModel>> = this.isCompaniesTable ? companiesHeaders : vesselHeaders;

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async created() {
    await this.getVessels();
  }

  private async getVessels() {
    try {
      this.loading = true;
      if (userModule.role == "AGM") {
        const response = await Api.VesselService.apiVesselByagencyWithSponsoredUserIdDiscriminatorGet(
          userModule.userId,
          this.isCompaniesTable ? ContractType.NUMBER_1 : ContractType.NUMBER_0
        );
        this.vessels = response.data;
      } else {
        const response = await Api.VesselService.apiVesselDiscriminatorGet(this.isCompaniesTable ? ContractType.NUMBER_1 : ContractType.NUMBER_0);
        this.vessels = response.data;
      }
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch vessels");
    } finally {
      this.loading = false;
    }
  }

  private updateNote(item: VesselModel) {
    return item.contactVessel ? "error--text" : "";
  }
}
